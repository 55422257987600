import React from 'react';
import { SvgIcon } from '@mui/material';

export function TipIcon(props) {
  return (
    <SvgIcon
      sx={{ width: '80px', height: '42px', viewBox: '0 0 80px 42px' }}
      {...props}
      viewBox="0 0 80 42"
      fill="none"
    >
      <g>
        <path d="M0 4.41139V8.82278H4.23529H8.47059V24.9114V41H13.5H18.5294V24.9114V8.82278H22.7647H27V4.41139V0H13.5H0V4.41139Z" />
        <path d="M32.1651 1.30363C28.5823 3.70232 27.2842 8.55183 28.842 13.7142L29.5689 16.0608L27.2323 18.3552C19.963 25.3948 20.3264 36.0324 27.9593 40.5169C31.1785 42.3941 35.5921 42.4984 38.9152 40.7776C40.7844 39.7869 41.1479 39.7347 41.4075 40.4126C41.6672 41.0384 42.7056 41.1948 46.8595 41.1948C49.6634 41.1948 52 41.0905 52 40.9341C52 40.7776 51.0654 38.7961 49.923 36.5017L47.8461 32.3301L49.1961 29.6707C50.65 26.7505 52 21.2753 52 18.0944V16.165H47.8461H43.6922L43.6403 19.3981V22.6832L40.8364 17.208C36.8382 9.33401 37.0979 6.83104 41.8748 7.45678L44.2114 7.8218V3.9109V0H39.1229C34.6055 0 33.8786 0.156433 32.1651 1.30363Z" />
        <path d="M53 20.5V41H58.0425H63.0851V32.9557V24.9114H66.6945C75.4526 24.9114 80.7605 19.1506 79.9112 10.3797C79.5928 7.00633 78.5843 4.77468 76.4611 2.85443C74.0194 0.622787 70.7285 0 61.3865 0H53V20.5ZM68.7115 9.44557C69.083 9.80886 69.5608 10.8987 69.7731 11.781C70.2508 14.1165 67.7561 16.6076 64.9959 16.6076H63.0851V12.4038V8.1481L65.5267 8.4595C66.8537 8.56329 68.2869 9.03038 68.7115 9.44557Z" />
      </g>
    </SvgIcon>
  );
}

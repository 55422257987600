import React from 'react';
import { SvgIcon } from '@mui/material';

export function YouTubeIcon(props) {
  return (
    <SvgIcon
      sx={{ width: '150px', height: '33px', viewBox: '0 0 150px 33px' }}
      {...props}
      viewBox="0 0 150 33"
      fill="none"
    >
      <g>
        <path d="M69.5843 30.0036C68.6446 29.3659 67.9789 28.3696 67.5873 26.9746C67.1958 25.6196 67 23.7862 67 21.5543V18.4855C67 16.2138 67.2349 14.3804 67.6657 12.9855C68.1355 11.5906 68.8404 10.5942 69.7801 9.95652C70.759 9.31884 72.0121 9 73.5783 9C75.1054 9 76.3584 9.31884 77.2982 9.95652C78.238 10.5942 78.9036 11.6304 79.3343 12.9855C79.7651 14.3406 80 16.1739 80 18.4855V21.5543C80 23.8261 79.8042 25.6196 79.3735 27.0145C78.9428 28.3696 78.2771 29.4058 77.3374 30.0435C76.3976 30.6812 75.1054 31 73.5 31C71.8554 30.9601 70.5241 30.6413 69.5843 30.0036ZM74.8705 26.6558C75.1446 25.9783 75.2621 24.8225 75.2621 23.2681V16.6522C75.2621 15.1377 75.1446 14.0217 74.8705 13.3043C74.5964 12.587 74.1657 12.2681 73.5 12.2681C72.8735 12.2681 72.4036 12.6268 72.1687 13.3043C71.8946 14.0217 71.7771 15.1377 71.7771 16.6522V23.2681C71.7771 24.8225 71.8946 25.9783 72.1687 26.6558C72.4036 27.3333 72.8735 27.692 73.5392 27.692C74.1657 27.692 74.5964 27.3732 74.8705 26.6558Z" />
        <path d="M142.391 21.6969V22.775C142.391 24.1325 142.429 25.1706 142.505 25.8494C142.58 26.5281 142.732 27.0472 142.997 27.3267C143.224 27.6461 143.603 27.8058 144.132 27.8058C144.814 27.8058 145.268 27.5263 145.533 26.9673C145.798 26.4083 145.912 25.49 145.95 24.1724L149.849 24.412C149.886 24.6116 149.886 24.8512 149.886 25.1706C149.886 27.127 149.394 28.6044 148.372 29.5626C147.35 30.5209 145.912 31 144.057 31C141.823 31 140.271 30.2414 139.363 28.7641C138.454 27.2868 138 25.0109 138 21.8966V18.1833C138 14.9891 138.454 12.6733 139.401 11.196C140.347 9.71869 141.937 9 144.17 9C145.722 9 146.934 9.31942 147.767 9.91833C148.599 10.5172 149.167 11.4755 149.508 12.7132C149.849 13.9909 150 15.7477 150 17.9837V21.6171H142.391V21.6969ZM142.959 12.7532C142.732 13.0726 142.58 13.5517 142.505 14.2305C142.429 14.9093 142.391 15.9474 142.391 17.3448V18.8621H145.722V17.3448C145.722 15.9873 145.685 14.9492 145.609 14.2305C145.533 13.5118 145.344 13.0327 145.117 12.7532C144.89 12.4737 144.511 12.314 144.057 12.314C143.527 12.274 143.186 12.4338 142.959 12.7532Z" />
        <path d="M58.1908 21.6118L53 2H57.542L59.374 10.8711C59.8321 13.059 60.1756 14.9287 60.4046 16.4801H60.5191C60.6718 15.3663 61.0153 13.5364 61.5496 10.9108L63.458 2H68L62.7328 21.6118V31H58.229V21.6118H58.1908Z" />
        <path d="M96 10V30.6513H92.24L91.84 28.1328H91.72C90.72 30.0314 89.16 31 87.12 31C85.72 31 84.68 30.5351 84 29.6439C83.32 28.7528 83 27.3579 83 25.4207V10H87.8V25.1494C87.8 26.0793 87.92 26.738 88.12 27.1255C88.32 27.5129 88.68 27.7066 89.16 27.7066C89.56 27.7066 89.96 27.5904 90.36 27.3192C90.76 27.0867 91.04 26.7768 91.2 26.3893V10H96Z" />
        <path d="M120 10V30.6513H116.24L115.84 28.1328H115.72C114.72 30.0314 113.16 31 111.12 31C109.72 31 108.68 30.5351 108 29.6439C107.32 28.7528 107 27.3579 107 25.4207V10H111.8V25.1494C111.8 26.0793 111.92 26.738 112.12 27.1255C112.32 27.5129 112.68 27.7066 113.16 27.7066C113.56 27.7066 113.96 27.5904 114.36 27.3192C114.76 27.0867 115.04 26.7768 115.2 26.3893V10H120Z" />
        <path d="M108 5.85871H103.307V31H98.693V5.85871H94V2H108V5.85871Z" />
        <path d="M134.574 13.1176C134.304 11.7843 133.839 10.8431 133.22 10.2549C132.601 9.66667 131.711 9.35294 130.628 9.35294C129.777 9.35294 128.964 9.58824 128.229 10.098C127.494 10.5686 126.914 11.2353 126.527 12.0196H126.488V1H122V30.6471H125.83L126.295 28.6863H126.411C126.759 29.3922 127.301 29.9412 128.036 30.3725C128.771 30.7647 129.545 31 130.435 31C132.021 31 133.143 30.2549 133.878 28.8039C134.613 27.3529 135 25.0392 135 21.9412V18.6471C134.961 16.2549 134.845 14.4118 134.574 13.1176ZM130.318 21.6275C130.318 23.1569 130.241 24.3333 130.125 25.1961C130.009 26.0588 129.777 26.6863 129.506 27.0392C129.196 27.3922 128.81 27.5882 128.345 27.5882C127.958 27.5882 127.61 27.5098 127.301 27.3137C126.991 27.1176 126.72 26.8431 126.527 26.4902V14.6471C126.682 14.098 126.952 13.6275 127.339 13.2353C127.726 12.8824 128.152 12.6863 128.577 12.6863C129.042 12.6863 129.429 12.8824 129.661 13.2353C129.932 13.6275 130.086 14.2549 130.202 15.1176C130.318 16.0196 130.357 17.2745 130.357 18.8824V21.6275H130.318Z" />
        <path d="M47.0113 5.16353C46.4578 3.14471 44.8367 1.55294 42.7807 1.00941C39.0245 0 23.9999 0 23.9999 0C23.9999 0 8.97525 0 5.21909 0.970588C3.20262 1.51412 1.542 3.14471 0.988464 5.16353C0 8.85176 0 16.5 0 16.5C0 16.5 0 24.1871 0.988464 27.8365C1.542 29.8553 3.16308 31.4471 5.21909 31.9906C9.01479 33 23.9999 33 23.9999 33C23.9999 33 39.0245 33 42.7807 32.0294C44.8367 31.4859 46.4578 29.8941 47.0113 27.8753C47.9998 24.1871 47.9998 16.5388 47.9998 16.5388C47.9998 16.5388 48.0393 8.85176 47.0113 5.16353Z" />
        <path d="M19 23V9L31 16L19 23Z" fill="#4520AB" />
      </g>
    </SvgIcon>
  );
}

import React from 'react';
import { SvgIcon } from '@mui/material';

export function SchoolIcon(props) {
  return (
    <SvgIcon
      sx={{ width: '45px', height: '44px', viewBox: '0 0 45px 44px' }}
      {...props}
      viewBox="0 0 45 44"
      fill="none"
    >
      <g>
        <path d="M22.4952 0C10.0771 0 0 9.85179 0 21.9995C0 34.1482 10.0771 44 22.4952 44C34.9229 44 45 34.1482 45 21.9995C45 9.85179 34.9229 0 22.4952 0Z" />
        <path
          d="M26.6585 19.9534C28.1019 19.3753 28.9811 18.4891 29.5565 17.8163C30.6583 16.5844 31.1079 15.0739 31.1079 13.6426C31.1079 12.4886 30.78 10.183 28.7002 8.59409C27.2705 7.52038 26.0067 7.00272 22.9871 7.00272H21.4584C21.4078 7.0007 21.3572 6.99981 21.3066 7.00003H15V36H33V30.4342C33 25.1091 31.236 21.5284 26.6585 19.9534ZM28.9228 34.4033H25.6762V24.7232H21.9297V34.4136H19.0591V8.44729L22.0069 8.44831C23.1098 8.44831 24.7828 8.68636 25.9671 10.1176C26.5823 10.8435 26.9615 11.8064 27.0933 12.7729H21.9297V14.2032H27.1393C27.0991 15.1259 26.9049 16.1589 25.9772 17.2552C25.1616 18.2501 23.8196 19.1729 21.926 19.1729C21.9143 19.1729 21.9043 19.1723 21.8927 19.1723V20.6216C26.9536 20.6216 28.9228 23.8962 28.9228 29.8933V34.4033Z"
          fill="#4520AB"
        />
      </g>
    </SvgIcon>
  );
}

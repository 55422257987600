import React from 'react';
import { SvgIcon } from '@mui/material';

export function ForbesIcon(props) {
  return (
    <SvgIcon
      sx={{ width: '140px', height: '35px', viewBox: '0 0 140px 35px' }}
      {...props}
      viewBox="0 0 140 35"
      fill="none"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.0713 9.94573C82.8282 9.94573 81.0578 10.4277 79.2967 11.3917C79.2967 6.25663 79.2967 1.60355 79.4543 0L68.7021 1.92798V2.89195L69.8237 3.04953C71.2697 3.37395 71.7516 4.1711 72.0668 6.09907C72.3912 9.955 72.2244 30.4953 72.0668 33.8692C74.9587 34.5088 78.0083 34.9908 81.0578 34.9908C89.5668 34.9908 94.7019 29.6981 94.7019 21.3467C94.7019 14.7656 90.5308 9.94573 85.0713 9.94573ZM81.3822 33.2204C80.7427 33.2204 79.9362 33.2204 79.4543 33.0628C79.2967 30.8197 79.1298 21.3467 79.2967 12.9953C80.2607 12.6708 80.9002 12.5132 81.7067 12.5132C85.2382 12.5132 87.1661 16.6843 87.1661 21.8194C87.1569 28.2521 84.7469 33.2204 81.3822 33.2204Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.2521 0.639648H0V2.08562L1.60356 2.24321C3.6891 2.56763 4.4955 3.84675 4.81065 6.89627C5.29264 12.6709 5.13507 23.1079 4.81065 28.0854C4.48623 31.1349 3.6891 32.5809 1.60356 32.7385L0 33.0629V34.342H18.1396V33.0629L16.2116 32.7385C14.1261 32.5809 13.3197 31.1349 13.0045 28.0854C12.8469 25.9998 12.6801 22.6259 12.6801 18.4548L16.5361 18.6124C18.946 18.6124 20.0676 20.5404 20.5495 23.2655H21.9955V11.8738H20.5495C20.0676 14.5989 18.946 16.5269 16.5361 16.5269L12.6801 16.6844C12.6801 11.2249 12.8377 6.08986 13.0045 3.04034H18.6216C22.9595 3.04034 25.2026 5.76546 26.8062 10.5854L28.4097 10.1034L28.2521 0.639648Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4004 9.63086C45.4274 9.63086 49.441 15.0904 49.441 22.311C49.441 29.2164 44.9455 34.9911 36.9184 34.9911C28.8914 34.9911 24.8779 29.5316 24.8779 22.311C24.8779 15.4055 29.3734 9.63086 37.4004 9.63086ZM36.9184 11.2344C33.3869 11.2344 32.4229 16.0451 32.4229 22.311C32.4229 28.41 34.0265 33.3875 37.2429 33.3875C40.932 33.3875 41.8959 28.5676 41.8959 22.311C41.8959 16.2119 40.2924 11.2344 36.9184 11.2344Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.7871 22.4685C96.7871 15.8875 100.958 9.63086 109.143 9.63086C115.881 9.63086 119.098 14.6084 119.098 21.1894H104.165C104.008 27.1309 106.89 31.4595 112.674 31.4595C115.242 31.4595 116.53 30.82 118.134 29.6984L118.773 30.5048C117.17 32.7479 113.638 35.0003 109.143 35.0003C101.922 34.991 96.7871 29.856 96.7871 22.4685ZM104.175 19.419L111.72 19.2614C111.72 16.0543 111.238 11.2344 108.512 11.2344C105.778 11.2344 104.332 15.7299 104.175 19.419Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.202 10.9098C136.274 10.1033 133.706 9.62134 130.824 9.62134C124.882 9.62134 121.193 13.1529 121.193 17.3239C121.193 21.495 123.918 23.2654 127.774 24.5446C131.787 25.9905 132.909 27.1121 132.909 29.04C132.909 30.968 131.463 32.7292 128.896 32.7292C125.846 32.7292 123.603 30.968 121.832 26.1481L120.711 26.4725L120.869 33.5356C122.796 34.342 126.328 34.9815 129.378 34.9815C135.634 34.9815 139.648 31.7744 139.648 26.7969C139.648 23.423 137.877 21.5043 133.706 19.8915C129.211 18.1304 127.607 16.9995 127.607 14.914C127.607 12.8284 129.053 11.3825 130.981 11.3825C133.873 11.3825 135.801 13.1436 137.238 17.4815L138.359 17.1571L138.202 10.9098Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.3057 10.2703C67.5806 8.66673 62.7607 9.46386 60.1932 15.2478L60.3508 9.63071L49.5986 11.7163V12.6802L50.7202 12.8378C52.1662 12.9954 52.8057 13.8018 52.9633 15.8873C53.2877 19.7433 53.1209 26.4819 52.9633 29.8558C52.8057 31.7838 52.1569 32.7478 50.7202 32.9054L49.5986 33.0629V34.3421H64.5218V33.0629L62.5939 32.9054C60.9903 32.7478 60.5083 31.7838 60.3508 29.8558C60.0263 26.8063 60.0263 20.7073 60.1932 16.8513C60.9996 15.7298 64.5311 14.7658 67.7382 16.8513L70.3057 10.2703Z"
        />
      </g>
    </SvgIcon>
  );
}

import React from 'react';
import { SvgIcon } from '@mui/material';

export function VedomostiIcon(props) {
  return (
    <SvgIcon
      sx={{ width: '140px', height: '25px', viewBox: '0 0 140px 25px' }}
      {...props}
      viewBox="0 0 140 25"
      fill="none"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.22708 10.4013V1.67914H7.98275C8.54936 1.67914 9.49436 1.58608 9.49436 4.19775V7.83592C9.49436 10.4479 8.54907 10.4013 7.98275 10.4013H7.22708ZM1.41709 20.8491H0.000282369V21.5959H10.2969C12.8006 21.5959 15.3986 20.7087 15.3986 16.6977C15.3986 13.9464 14.4065 11.3342 10.5331 10.9608V10.6343C12.4228 10.495 15.0679 9.60833 15.0679 5.7375C15.0679 1.35286 11.6194 0.886418 10.4865 0.886418H0V1.67942H1.4168L1.41709 20.8491ZM7.22708 20.8491V11.1475H7.98275C8.54936 11.1475 9.49436 11.101 9.49436 13.7129V18.2826C9.49436 20.8948 8.54907 20.8491 7.98275 20.8491H7.22708Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8075 1.67942H16.343V0.886418H29.3795V8.30236H28.9072C28.292 1.72595 24.9863 1.67914 23.9474 1.67914H23.5695V10.7744H23.8526C25.742 10.7744 26.498 8.44279 26.4034 6.34354H26.9229V15.905H26.4034C26.498 13.806 25.742 11.5206 23.8526 11.5206H23.5695V20.8491C27.5369 20.9428 28.9069 17.304 29.2376 13.6199H29.7568V21.5959H16.3425V20.8491H17.807L17.8075 1.67942Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.7491 1.67942V20.8494H32.9231C34.6232 18.3776 34.8123 14.7857 34.8123 9.65542V3.35905C34.8123 1.72651 35.3321 1.6797 35.757 1.6797L36.7491 1.67942ZM32.3562 0.886697V1.6797H33.1119C33.5843 1.6797 34.2457 1.77332 34.2457 3.59199V9.6557C34.2457 18.0513 33.0648 20.8497 31.223 20.8497H30.9394V25H31.4118C31.506 21.923 32.6401 21.5964 35.1433 21.5964H40.1499C42.6537 21.5964 43.7872 21.923 43.8817 25H44.3541V20.8494H42.5591V1.67942H43.9759V0.886418L32.3562 0.886697Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.2569 3.12555C50.2569 1.81958 50.6821 1.26007 51.3906 1.26007C52.0521 1.26007 52.5241 1.81986 52.5241 3.12555V19.4509C52.5241 20.7093 52.0521 21.2696 51.3906 21.2696C50.6821 21.2696 50.2569 20.709 50.2569 19.4509V3.12555ZM44.1164 11.2877C44.1164 16.4185 46.5725 22.0609 51.3904 22.0609C56.2077 22.0609 58.6166 16.4185 58.6166 11.2877C58.6166 6.1106 56.2077 0.467071 51.3904 0.467071C46.5725 0.467071 44.1164 6.1106 44.1164 11.2877Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.1686 3.26543H61.1215V18.2829C61.1215 20.6165 61.9242 20.8494 62.5854 20.8494H62.9632V21.5962H59.0901V20.8494C59.7038 20.8951 60.5546 20.5685 60.5546 18.2829V1.67942H59.0901V0.886418H66.364L68.2058 9.32886H68.3006L70.1897 0.886418H77.4639V1.67942H76.0466V20.8494H77.4639V21.5962H68.8198V20.8494H70.2366V3.35905H70.1423L66.033 21.5962H65.2302L61.1686 3.26543Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.2647 3.12555C84.2647 1.81958 84.7365 1.26007 85.3979 1.26007C86.1067 1.26007 86.5311 1.81986 86.5311 3.12555V19.4509C86.5311 20.7093 86.1067 21.2696 85.3979 21.2696C84.7365 21.2696 84.2647 20.709 84.2647 19.4509V3.12555ZM78.1711 11.2877C78.1711 16.4185 80.58 22.0609 85.3979 22.0609C90.2146 22.0609 92.6715 16.4185 92.6715 11.2877C92.6715 6.1106 90.2146 0.467071 85.3979 0.467071C80.58 0.467071 78.1711 6.1106 78.1711 11.2877Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.794 7.69625H106.274C105.801 4.33839 104.149 1.72616 102.259 1.72616C100.18 1.72616 100.087 4.75802 100.087 6.20387V15.765C100.087 16.6976 100.134 20.2416 102.495 20.2416C105.14 20.2416 106.18 16.6508 106.558 13.899L107.03 14.0392C106.605 17.4912 105.282 22.0603 101.22 22.0603C96.3544 22.0603 93.9929 16.1847 93.9929 11.2408C93.9929 5.31696 96.9222 0.466439 101.22 0.466439C102.071 0.466439 103.345 0.746192 104.527 1.81895L106.085 0H106.558L106.794 7.69625Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.18 1.67914C109.487 1.91236 108.543 6.06378 108.354 8.30236H107.881V0.886418H122.288V8.30236H121.768C121.579 6.06406 120.634 1.91236 117.987 1.67914V20.8491H119.689V21.5959H110.43V20.8491H112.178V1.67914H112.18Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M130.508 15.3455H130.601L132.773 3.07846V1.67914H132.065V0.886418H140V1.67914H138.583V20.8491H140V21.5959H132.065V20.8491H132.773V6.34354H132.68L130.508 18.5635V20.8491H131.216V21.5959H123.278V20.8491H124.698V1.67914H123.278V0.886418H131.216V1.67914H130.508V15.3455Z"
        />
      </g>
    </SvgIcon>
  );
}

import React from 'react';
import { SvgIcon } from '@mui/material';

export function CehIcon(props) {
  return (
    <SvgIcon
      sx={{ width: '100px', height: '47px', viewBox: '0 0 100px 47px' }}
      {...props}
      viewBox="0 0 100 47"
      fill="none"
    >
      <path d="M0 8.88971C0 12.5937 8.12606 17.0797 13.9304 18.5202L14.5108 15.6804C10.8209 14.6927 2.81924 10.824 2.81924 8.80737C4.8093 7.40805 16.6667 3.5394 22.8027 2.51051L19.6933 30.7023C19.3616 33.5832 18.6567 36.7933 21.0199 36.7933C24.8757 36.7933 34.5772 20.4957 39.7182 9.38358C38.5988 15.8862 37.6038 24.3231 37.811 31.6077L38.5988 33.1716L44.0715 34.077L37.6866 43.7898C36.36 43.5429 33.5822 42.3906 33.5822 39.5096C33.5822 36.176 37.0648 33.1305 53.0267 25.352L53.3169 22.7592C32.9188 32.3074 30.8874 35.929 30.8874 39.5508C30.8874 43.296 33.5407 45.8064 39.0134 47L48.4661 32.1428L40.5474 30.8669C40.1742 24.2819 42.2887 9.2189 43.2008 2.7986L40.4645 2.30473C36.4014 11.8117 27.2389 27.1628 22.0979 33.6655V33.6244L25.4147 4.11559C25.6635 1.97549 24.7099 0 23.3831 0C20.5224 0 7.42125 4.03327 3.85573 5.5972C1.1194 6.79072 0 7.77846 0 8.88971ZM53.1925 26.9159C53.1925 23.2942 58.292 14.9808 61.4429 14.9808C62.2307 14.9808 62.6453 15.31 62.6453 16.2566C62.6453 17.409 58.0019 20.4957 53.773 22.5946L53.3169 25.2697C61.2357 22.0184 65.2572 18.1909 65.2572 16.0508C65.2572 14.0341 64.2208 12.4703 61.5259 12.4703C56.4678 12.4703 50.1245 21.6891 50.1245 26.9982C50.1245 31.0315 51.9073 33.9124 55.763 33.9124C62.6453 33.9124 69.8592 24.7347 73.4662 16.6682C74.4612 18.4378 76.4513 21.4422 77.8609 22.9649C74.6271 26.2163 71.9323 29.4676 69.8592 32.5954L71.9737 33.6655C73.9637 30.7023 76.244 27.6979 79.312 24.6524C83.3751 29.0148 89.5522 33.9948 93.0348 33.9948C95.4812 33.9948 98.7983 27.9449 100 25.5166L97.3057 24.4878C96.0619 27.0394 94.403 30.2907 92.7037 31.6077C88.4748 29.303 84.6189 26.2163 81.385 22.6357C86.1114 18.1498 92.7037 13.1699 98.2175 10.1244L97.1393 8.19003C91.2523 11.4413 85.2407 16.0508 79.9339 20.9483C78.2756 18.9317 76.5757 16.2566 75.2905 13.5403L72.3468 13.6637C68.3667 24.6112 60.365 31.69 56.6337 31.6489C54.1876 31.6489 53.1925 29.9615 53.1925 26.9159Z" />
    </SvgIcon>
  );
}

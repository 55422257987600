import React from 'react';
import { SvgIcon } from '@mui/material';

export function VcIcon(props) {
  return (
    <SvgIcon
      sx={{ width: '46px', height: '44px', viewBox: '0 0 46px 44px' }}
      {...props}
      viewBox="0 0 46 44"
      fill="none"
    >
      <path d="M36.9099 20.2851C40.319 20.2851 42.8855 18.8298 44.7779 16.2964L42.2487 14.3191C41.6239 15.1627 40.8165 15.8462 39.8901 16.3156C38.9636 16.785 37.9434 17.0275 36.9099 17.024C33.21 17.024 30.529 13.9355 30.529 10.1458C30.529 6.35613 33.1663 3.09373 37.0154 3.09373C38.7263 3.09373 39.9356 3.50762 40.9905 4.17724V7.50951H44.1732V2.65083L43.5634 2.15914C41.8139 0.748712 39.1419 0 36.9086 0C31.4488 0 27.008 4.55029 27.008 10.1445C27.008 15.7335 31.4463 20.2851 36.9086 20.2851H36.9099ZM43.5133 40.2947L43.4001 35.6244V24.2277H36.7619V27.4901H40.0475V35.2224C40.0475 38.5534 37.5647 40.9115 35.0599 40.9115C32.5552 40.9115 31.2481 39.1321 31.2481 35.8024V24.2264H25.0474V27.4888H27.8956V36.3679C27.8956 40.8865 30.3682 44 34.7782 44C37.3125 44 39.2422 42.5592 40.3936 40.3381H40.4451L40.8992 43.4437H46V40.2986H43.5171V40.292L43.5133 40.2947ZM26.2747 0.478499H20.7429L15.9315 14.7936H15.8569L11.0713 0.478499H5.17672V3.74223H8.49193L14.1974 20.0584H17.2283V20.0544L23.1782 3.74355H26.2721V0.479826L26.2747 0.478499ZM0 43.4859H3.2779V40.1286H0.00128646V43.4859H0ZM12.6588 28.0108H12.6228L12.4169 24.2277H6.5661V27.2015H9.67933V40.296H6.5661V43.4424H21.0259V40.3579H12.9753V32.4556C13.3393 29.5952 15.4324 27.1527 18.2883 27.1527H18.3398L18.2819 30.0763H21.4646V24.1736H18.9483C16.0666 24.1657 13.9285 25.7633 12.6575 28.0121L12.6588 28.0108Z" />
    </SvgIcon>
  );
}
